<script>

import appConfig from "../../../app.config";


export default {

  page: {
    title: "Logout",
    meta: [{
      name: "description",
      content: appConfig.description,
    },],
  },
  data() {
    return {
      
    };
  },
  validations: {
    
  },
  computed: {

  },
  mounted: function () {
    localStorage.removeItem("userdata");
    localStorage.removeItem("userid");
    localStorage.removeItem("jwt");
    localStorage.removeItem('user');
    localStorage.removeItem('username');
    localStorage.removeItem('useremail');
    localStorage.removeItem('usertype');
    localStorage.removeItem('work_type_string');

    this.$router.push({
          path: '/login'
        });
    // alert(okmsg);
    //this.okmsg = this.$route.params.okmsg

  },
  methods: {
   
  },
};
</script>

<template>
  <div class="auth-page-wrapper">
   
  </div>
 
</template>